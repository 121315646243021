define("discourse/plugins/discourse-rss-polling/discourse/controllers/admin-plugins-rss-polling", ["exports", "@ember/controller", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _rssPollingFeedSettings, _object, _computed, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("valid", "saving"), _dec2 = (0, _decorators.observes)("feedSettings.@each.{feed_url,author_username}"), (_obj = {
    feedSettings: (0, _computed.alias)("model"),
    saving: false,
    valid: false,
    unsavable(valid, saving) {
      return !valid || saving;
    },
    validate() {
      let overallValidity = true;
      this.get("feedSettings").forEach(feedSetting => {
        const localValidity = !(0, _utils.isBlank)(feedSetting.feed_url) && !(0, _utils.isBlank)(feedSetting.author_username);
        (0, _object.set)(feedSetting, "valid", localValidity);
        overallValidity = overallValidity && localValidity;
      });
      this.set("valid", overallValidity);
    },
    actions: {
      create() {
        this.get("feedSettings").addObject({
          feed_url: null,
          author_username: null,
          discourse_category_id: null,
          discourse_tags: null,
          feed_category_filter: null
        });
      },
      destroy(feedSetting) {
        this.get("feedSettings").removeObject(feedSetting);
        this.send("update");
      },
      update() {
        this.set("saving", true);
        _rssPollingFeedSettings.default.update(this.get("feedSettings")).then(updatedSettings => {
          this.set("feedSettings", updatedSettings["feed_settings"]);
        }).finally(() => {
          this.set("saving", false);
        });
      },
      updateAuthorUsername(setting, selected) {
        (0, _object.set)(setting, "author_username", selected.firstObject);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "unsavable", [_dec], Object.getOwnPropertyDescriptor(_obj, "unsavable"), _obj), _applyDecoratedDescriptor(_obj, "validate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "validate"), _obj)), _obj)));
});